<template>
  <div>
    <GenericTable
      ref="lista"
      name="tipo de esterilização"
      title="esterilizações"
      add-route="tipoEsterilizacaoAdicionar"
      edit-route="tipoEsterilizacaoEditar"
      :nofilters="true"
      :cols="['Nome', 'Descrição']"
      :cols-name="['nome', 'descricao']"
      :cols-map="i => [i.nome, i.descricao]"
      :route="tipoEsterilizacaoRoute"
      :permissionsToWrite="['rw_esterilizacao']"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      tipoEsterilizacaoRoute: 'tipoEsterilizacao',
    };
  },
};
</script>
